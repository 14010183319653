import React from 'react';
import { Items } from './items';
import Header from './header';
import Categories from './categories';
// import ModalBucha from './ModalBucha';

// СЕТИ!!!!

import yamato from '../images/yamato.jpg';
import biglovefilla from '../images/biglovefilla.jpg';
import biglove from '../images/biglove.jpg';
import firmoviy from '../images/firmoviy.jpg';
import tridrakona from '../images/tridrakona.jpg';
import alyaska from '../images/alyaska.jpg';
import filadelphia from '../images/filadelphia.jpg';
import ronin from '../images/ronin.jpg';
import futomaki from '../images/futomaki.jpg';
import nindzya from '../images/nindzya.jpg';
import fudzi from '../images/fudzi.png';
import number1 from '../images/number1.jpg';
import number2 from '../images/number2.jpg';
import number3 from '../images/number3.jpg';
import number4 from '../images/number4.jpg';
import makiset from '../images/makiset.jpg';

// РОЛИ!!!!

import rollalaska from '../images/images/rollalaska.jpg';
import rollreddragon from '../images/images/rollreddragon.jpg';
import rollgreendragon from '../images/images/rollgreendragon.jpg';
import rollgolddragon from '../images/images/rollgolddragon.jpg';
import rollblackimperator from '../images/images/rollblackimperator.jpg';
import rollgreensamyrai from '../images/images/rollgreensamyrai.jpg';
import rollyamato from '../images/images/rollyamato.jpg';
import rollfilla from '../images/images/rollfilla.jpg';
import rollfillakop from '../images/images/rollfillakop.jpg';
import rollfilladelux from '../images/images/rollfilladelux.jpg';
import rollfillashef from '../images/images/rollfillashef.jpg';
import rollfillavugor from '../images/images/rollfillavugor.jpg';
import rollfillamas from '../images/images/rollfillamas.jpg';
import rollfillachuka from '../images/images/rollfillachuka.jpg';
import cheeseroll from '../images/images/cheeseroll.jpg';
import rollkanada from '../images/images/rollkanada.jpg';
import rollbezrice from '../images/images/rollbezrice.jpg';
import rollgrillfilla from '../images/images/rollgrillfilla.jpg';
import rolloysi from '../images/images/rolloysi.jpg';
import rollinyan from '../images/images/rollinyan.jpg';
import rollfirmoviy from '../images/images/rollfirmoviy.jpg';
import rollnizlos from '../images/images/rollnizlos.jpg';
import rollnizkrab from '../images/images/rollnizkrab.jpg';
import rollyakydza from '../images/images/rollyakydza.jpg';
import rollnindza from '../images/images/rollnindza.jpg';
import chukaroll from '../images/images/chukaroll.jpg';
import spaysylos from '../images/images/spaysylos.jpg';
import spaysykrev from '../images/images/spaysykrev.jpg';
import rollfutomakivug from '../images/images/rollfutomakivug.jpg';
import rollfutomakilos from '../images/images/rollfutomakilos.jpg';
// import rollfutomakikrev from '../images/images/rollfutomakikrev.jpg';
import rolic from '../images/rolic.jpg';
import rollfutomakikop from '../images/images/rollfutomakikop.jpg';
import rollkaliforniyadelux from '../images/images/rollkaliforniyadelux.jpg';
import rollkaliforniyasake from '../images/images/rollkaliforniyasake.jpg';
import rollkaliforniyalos from '../images/images/rollkaliforniyalos.jpg';
import rollkaliforniyaloskun from '../images/images/rollkaliforniyaloskun.jpg';
import rollkaliforniyavugikra from '../images/images/rollkaliforniyavugikra.jpg';
import rollkaliforniyavugkun from '../images/images/rollkaliforniyavugkun.jpg';
import rollkaliforniyakrevikra from '../images/images/rollkaliforniyakrevikra.jpg';
import rollkaliforniyakrevkun from '../images/images/rollkaliforniyakrevkun.jpg';
import rollkaliforniyakoplos from '../images/images/rollkaliforniyakoplos.jpg';
import rollkaliforniyakoploskun from '../images/images/rollkaliforniyakoploskun.jpg';

// ЗАПЕЧЕНІ РОЛИ!!!!

import zapechrollpers from '../images/images/zapechrollpers.jpg';
import zapechrollvug from '../images/images/zapechrollvug.jpg';
import zapechrollloskrev from '../images/images/zapechrollloskrev.jpg';
import zapechrollkun from '../images/images/zapechrollkun.jpg';
import zapechrollspaycy from '../images/images/zapechrollspaycy.jpg';
import zapechrollmas from '../images/images/zapechrollmas.jpg';
import zapechrolllos from '../images/images/zapechrolllos.jpg';
import zapechrollkrev from '../images/images/zapechrollkrev.jpg';

// МАКІ!!!!

import makilos from '../images/images/makilos.jpg';
import makikoplos from '../images/images/makikoplos.jpg';
import makivug from '../images/images/makivug.jpg';
import makikrev from '../images/images/makikrev.jpg';
import makicucumber from '../images/images/makicucumber.jpg';
import makiavocado from '../images/images/makiavocado.jpg';
import makilossir from '../images/images/makilossir.jpg';
import makisir from '../images/images/makisir.jpg';

// НІГІРІ!!!!

import nigiry from '../images/images/nigiry.jpg';
import nigiryvug from '../images/images/nigiryvug.jpg';

// import snails from '../images/snails.jpg';

// ГУНКАНИ!!!!

import gunkanlos from '../images/images/gunkanlos.jpg';
import gunkanvug from '../images/images/gunkanvug.jpg';
import gunkanmas from '../images/images/gunkanmas.jpg';
import gunkanredikra from '../images/images/gunkanredikra.jpg';
import gunkanlossir from '../images/images/gunkanlossir.jpg';
import gunkanvugsir from '../images/images/gunkanvugsir.jpg';
import gunkankrevsir from '../images/images/gunkankrevsir.jpg';
import gunkankrevmix from '../images/images/gunkankrevmix.jpg';
import gunkanlosmix from '../images/images/gunkanlosmix.jpg';
import gunkanchyka from '../images/images/gunkanchyka.jpg';

// НАПОЇ!!!!

import cola from '../images/images/cola.png';
import fanta from '../images/images/fanta.png';
import sprite from '../images/images/sprite.png';
import sik from '../images/images/sik.png';

// ДОДАТКИ!!!!

import vasabi from '../images/images/vasabi.png';
import imbir from '../images/images/imbir.png';
import soyeviy from '../images/images/soyeviy.png';
import ynagi from '../images/images/ynagi.png';
import gorix from '../images/images/gorix.png';
import spaycy from '../images/images/spaycy.png';

// ПОКЕ!!!!

import pokelos from '../images/pokelos.jpg';
import pokekrev from '../images/pokekrev.jpg';
import pokevug from '../images/pokevug.jpg';

// СПИНГРОЛ!!!!

import springlos from '../images/springlos.jpg';
import springkrev from '../images/springkrev.jpg';

// ШАУРМА!!!!

// import sushivug from '../images/sushivug.jpg';
// import sushilos from '../images/sushilos.jpg';
// import sushikrev from '../images/sushikrev';

import ShowFullItem from './showFullItem';
import Footer from './footer';
import Window from './window';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      checks: [],
      currentItems: [],
      items: [
        {
          id: 1,
          name: 'Сет "Ямато"',
          price: 2100,
          gram: 2200,
          image: yamato,
          category: 'Set',
          menu: 'Зелений самурай, Ямато, Філадельфія Deluxe, Золотий дракон, Аляска, Футомакі креветка, Ніжний ',
        },
        {
          id: 2,
          name: 'Сет "BIG LOVE FILLA"',
          price: 1600,
          gram: 1800,
          image: biglovefilla,
          category: 'Set',
          menu: 'Філадельфія, Філадельфія шеф, Ямато, Філадельфія вугор, філадельфія масаго, фелікс лосось-сир 4 шт',
        },
        {
          id: 3,
          name: 'Сет "BIG LOVE"',
          price: 1400,
          gram: 1600,
          image: biglove,
          category: 'Set',
          menu: 'Філадельфія, Зелений дракон, Каліфорнія лосось в ікрі, каліфорнія лосось в кунжуті, каліфорнія вугор в ікрі, каліфорнія креветка в кунжуті',
        },
        {
          id: 4,
          name: 'Сет "Фірмовий"',
          price: 1400,
          gram: 1300,
          image: firmoviy,
          category: 'Set',
          menu: 'Фірмовий, Аляска, Золотий дракон, Фелікс лосось-сир 4шт',
        },
        {
          id: 5,
          name: 'Сет "Три дракона"',
          price: 1000,
          gram: 900,
          image: tridrakona,
          category: 'Set',
          menu: 'Червоний дракон, Золотий дракон, Зелений дракон',
        },
        {
          id: 6,
          name: 'Сет "Аляска"',
          price: 1050,
          gram: 900,
          image: alyaska,
          category: 'Set',
          menu: 'Філадельфія Deluxe, Аляска, Нігірі лосось 4шт',
        },
        {
          id: 7,
          name: 'Сет "Філадельфія"',
          price: 820,
          gram: 900,
          image: filadelphia,
          category: 'Set',
          menu: 'Філадельфія, Філадельфія шеф, Філадельфія масаго',
        },
        {
          id: 8,
          name: 'Сет "Ронін"',
          price: 1200,
          gram: 1500,
          image: ronin,
          category: 'Set',
          menu: 'Футомакі вугор, Футомакі лосось, Філадельфія, Каліфорнія вугор в кунжуті, Каліфорнія лосось в ікрі ',
        },
        {
          id: 9,
          name: 'Сет "Футомакі"',
          price: 1000,
          gram: 1200,
          image: futomaki,
          category: 'Set',
          menu: 'Футомакі вугор, Футомакі лосось, Футомакі креветка, Футомакі з копченим лососем',
        },
        {
          id: 10,
          name: 'Сет "Ніндзя"',
          price: 850,
          gram: 850,
          image: nindzya,
          category: 'Set',
          menu: 'Каліфорнія сяке масаго, Рол Ніндзя, Зелений дракон ',
        },
        {
          id: 11,
          name: 'Сет "Фудзі"',
          price: 800,
          gram: 800,
          image: fudzi,
          category: 'Set',
          menu: 'Філадельфія шеф, Макі вугор, Макі лосось, Фелікс креветка-сир 3шт',
        },
        {
          id: 12,
          name: 'Сет №1',
          price: 500,
          gram: 650,
          image: number1,
          category: 'Set',
          menu: 'Каліфорнія лосось в ікрі, Каліфорнія з копченим лососем в кунжуті, Макі лосось',
        },
        {
          id: 13,
          name: 'Сет №2',
          price: 560,
          gram: 780,
          image: number2,
          category: 'Set',
          menu: 'Каліфорнія лосось в ікрі, Каліфорнія лосось в кунжуті, Макі авокадо, Макі огірок',
        },
        {
          id: 14,
          name: 'Сет №3',
          price: 600,
          gram: 750,
          image: number3,
          category: 'Set',
          menu: 'Каліфорнія вугор в кунжуті, Каліфорнія лосось в кунжуті, Макі креветка, Макі огірок',
        },
        {
          id: 15,
          name: 'Сет №4',
          price: 670,
          gram: 710,
          image: number4,
          category: 'Set',
          menu: 'Філадельфія, Макі креветка, Фелікс лосось-сир 2 шт, Фелікс креветка-сир 2шт',
        },
        {
          id: 16,
          name: 'Макі сет',
          price: 500,
          gram: 550,
          image: makiset,
          category: 'Set',
          menu: 'Макі лосось, Макі вугор, Макі огірок,Макі авокадо',
        },

        // РОЛИ!!!!

        {
          id: 17,
          name: 'Рол "Аляска"',
          price: 500,
          gram: 400,
          image: rollalaska,
          category: 'Rolu',
          menu: 'Лосось, огірок, крем-сир, ікра',
        },
        {
          id: 18,
          name: 'Рол "Червоний Дракон"',
          price: 350,
          gram: 320,
          image: rollreddragon,
          category: 'Rolu',
          menu: 'Лосось, вугор, огірок, масаго, крем - сир',
        },
        {
          id: 19,
          name: 'Рол "Зелений дракон"',
          price: 360,
          gram: 300,
          image: rollgreendragon,
          category: 'Rolu',
          menu: 'Вугор, авокадо, огірок, масаго, унагі, крем-сир, кунжут',
        },
        {
          id: 20,
          name: 'Рол "Золотий дракон"',
          price: 350,
          gram: 300,
          image: rollgolddragon,
          category: 'Rolu',
          menu: 'Вугор, крем-сир, огірок, масаго, унагі, кунжут',
        },
        {
          id: 21,
          name: 'Рол "Чeрвоний імператор"',
          price: 400,
          gram: 340,
          image: rollblackimperator,
          category: 'Rolu',
          menu: 'Вугор, ікра масаго, креветка, огірок, крем-сир',
        },
        {
          id: 22,
          name: 'Рол "Зелений самурай"',
          price: 420,
          gram: 300,
          image: rollgreensamyrai,
          category: 'Rolu',
          menu: 'Лосось, вугор, крем-сир, ікра, унагі, авокадо',
        },
        {
          id: 23,
          name: 'Рол "Ямато"',
          price: 300,
          gram: 300,
          image: rollyamato,
          category: 'Rolu',
          menu: 'Креветка, крем-сир, авокадо, огірок, масаго',
        },
        {
          id: 24,
          name: 'Рол "Філадельфія"',
          price: 300,
          gram: 300,
          image: rollfilla,
          category: 'Rolu',
          menu: 'Лосось, крем-сир, огірок',
        },
        {
          id: 25,
          name: 'Рол "Філадельфія з копченим лососем"',
          price: 310,
          gram: 300,
          image: rollfillakop,
          category: 'Rolu',
          menu: 'Копчений лосось, крем-сир, огірок',
        },
        {
          id: 26,
          name: 'Рол "Філадельфія Deluxe"',
          price: 330,
          gram: 310,
          image: rollfilladelux,
          category: 'Rolu',
          menu: 'Лосось, крем-сир, огірок, масаго, червона ікра',
        },
        {
          id: 27,
          name: 'Рол "Філадельфія шеф"',
          price: 320,
          gram: 300,
          image: rollfillashef,
          category: 'Rolu',
          menu: 'Лосось, авокадо, крем-сир, огірок, японський майонез',
        },
        {
          id: 28,
          name: 'Рол "Філадельфія вугор"',
          price: 330,
          gram: 300,
          image: rollfillavugor,
          category: 'Rolu',
          menu: 'Вугор, авокадо, крем-сир, унагі, кунжут',
        },
        {
          id: 29,
          name: 'Рол "Філадельфія масаго"',
          price: 270,
          gram: 300,
          image: rollfillamas,
          category: 'Rolu',
          menu: 'Лосось, авокадо, крем-сир, масаго',
        },
        {
          id: 30,
          name: 'Рол "Філадельфія чука"',
          price: 300,
          gram: 300,
          image: rollfillachuka,
          category: 'Rolu',
          menu: 'Салат «чука», лосось, крем-сир, горіховий соус',
        },
        {
          id: 31,
          name: 'Cheese roll',
          price: 300,
          gram: 300,
          image: cheeseroll,
          category: 'Rolu',
          menu: 'Вугор, огірок,гауда,крем-сир, унагі',
        },
        {
          id: 32,
          name: 'Рол "Канада"',
          price: 410,
          gram: 300,
          image: rollkanada,
          category: 'Rolu',
          menu: 'Крем-сир, креветка, вугор, лосось',
        },
        {
          id: 33,
          name: 'Рол без рису',
          price: 230,
          gram: 200,
          image: rollbezrice,
          category: 'Rolu',
          menu: 'Лосось, крем-сир, авокадо',
        },
        {
          id: 34,
          name: 'Рол "Grill filla"',
          price: 360,
          gram: 310,
          image: rollgrillfilla,
          category: 'Rolu',
          menu: 'Лосось, креветка, огірок, крем-сир, спайсі',
        },
        {
          id: 35,
          name: 'Рол "Ойсі"',
          price: 370,
          gram: 320,
          image: rolloysi,
          category: 'Rolu',
          menu: 'Крем-сир, огірок, вугор, лосось, соус унагі, соус спайсі, сушена цибуля',
        },
        {
          id: 36,
          name: 'Рол "Інь Янь"',
          price: 340,
          gram: 300,
          image: rollinyan,
          category: 'Rolu',
          menu: 'Лосось, вугор, крем-сир, огірок',
        },
        {
          id: 37,
          name: 'Рол "Фірмовий"',
          price: 330,
          gram: 290,
          image: rollfirmoviy,
          category: 'Rolu',
          menu: 'Крем-сир, ікра масаго, креветка, персик',
        },
        {
          id: 38,
          name: 'Рол "Ніжний з лососем"',
          price: 270,
          gram: 290,
          image: rollnizlos,
          category: 'Rolu',
          menu: 'Лосось, крем-сир, кунжут',
        },
        {
          id: 39,
          name: 'Рол "Ніжний з крабом"',
          price: 320,
          gram: 280,
          image: rollnizkrab,
          category: 'Rolu',
          menu: 'Лосось, мікс крабу, огірок, авокадо',
        },
        {
          id: 40,
          name: 'Рол "Якудза"',
          price: 300,
          gram: 280,
          image: rollyakydza,
          category: 'Rolu',
          menu: 'Крем-сир, лосось, огірок, ікра масаго, стружка тунця',
        },
        {
          id: 41,
          name: 'Рол "Ніндзя"',
          price: 290,
          gram: 290,
          image: rollnindza,
          category: 'Rolu',
          menu: 'Вугор, лосось, огірок, крем-сир, унагі, кунжут',
        },
        {
          id: 42,
          name: 'Чука рол',
          price: 200,
          gram: 270,
          image: chukaroll,
          category: 'Rolu',
          menu: 'Салат «чука», крем-сир, кунжут, горіховий соус',
        },
        {
          id: 43,
          name: 'Спайсі лосось',
          price: 280,
          gram: 300,
          image: spaysylos,
          category: 'Rolu',
          menu: 'Лосось спайсі, крем-сир, огірок, кунжут',
        },
        {
          id: 44,
          name: 'Спайсі креветка',
          price: 280,
          gram: 300,
          image: spaysykrev,
          category: 'Rolu',
          menu: 'Креветка спайсі, крем-сир, огірок, кунжут',
        },
        {
          id: 45,
          name: 'Рол "Футомакі вугор"',
          price: 300,
          gram: 300,
          image: rollfutomakivug,
          category: 'Rolu',
          menu: 'Вугор, персик, крем-сир, авокадо, унагі, кунжут',
        },
        {
          id: 46,
          name: 'Рол "Футомакі лосось"',
          price: 290,
          gram: 300,
          image: rollfutomakilos,
          category: 'Rolu',
          menu: 'Лосось, крем-сир, зелена цибуля, лист салату, огірок',
        },
        {
          id: 47,
          name: 'Рол "Футомакі креветка"',
          price: 280,
          gram: 300,
          // image: rollfutomakikrev,
          image: rolic,
          category: 'Rolu',
          menu: 'Креветка, крем-сир, масаго, чука, горіховий соус',
        },
        {
          id: 48,
          name: 'Рол "Футомакі з копченим лососем"',
          price: 290,
          gram: 300,
          image: rollfutomakikop,
          category: 'Rolu',
          menu: 'Копчений лосось, крем-сир, болгарський перець, лист салату',
        },
        {
          id: 49,
          name: 'Рол "Каліфорнія Deluxe"',
          price: 270,
          gram: 270,
          image: rollkaliforniyadelux,
          category: 'Rolu',
          menu: 'Лосось, вугор, огірок, масаго, унагі',
        },
        {
          id: 50,
          name: 'Рол "Каліфорнія сяке масаго"',
          price: 220,
          gram: 250,
          image: rollkaliforniyasake,
          category: 'Rolu',
          menu: 'Лосось, авокадо, огірок, масаго',
        },
        {
          id: 51,
          name: 'Рол "Каліфорнія лосось ікра"',
          price: 230,
          gram: 250,
          image: rollkaliforniyalos,
          category: 'Rolu',
          menu: 'Лосось, огірок, ікра масаго',
        },
        {
          id: 52,
          name: 'Рол "Каліфорнія лосось кунжут"',
          price: 230,
          gram: 250,
          image: rollkaliforniyaloskun,
          category: 'Rolu',
          menu: 'Лосось, огірок, кунжут',
        },
        {
          id: 53,
          name: 'Рол "Каліфорнія вугор ікра"',
          price: 240,
          gram: 230,
          image: rollkaliforniyavugikra,
          category: 'Rolu',
          menu: 'Вугор, огірок, ікра масаго, унагі',
        },
        {
          id: 54,
          name: 'Рол "Каліфорнія вугор кунжут"',
          price: 240,
          gram: 230,
          image: rollkaliforniyavugkun,
          category: 'Rolu',
          menu: 'Вугор, кунжут,огірок,унагі',
        },
        {
          id: 55,
          name: 'Рол "Каліфорнія креветка ікра"',
          price: 230,
          gram: 230,
          image: rollkaliforniyakrevikra,
          category: 'Rolu',
          menu: 'Креветка, огірок, масаго',
        },
        {
          id: 56,
          name: 'Рол "Каліфорнія креветка кунжут"',
          price: 230,
          gram: 230,
          image: rollkaliforniyakrevkun,
          category: 'Rolu',
          menu: 'Креветка, огірок, кунжут',
        },
        {
          id: 57,
          name: 'Рол "Каліфорнія копчений лосось в ікрі"',
          price: 240,
          gram: 250,
          image: rollkaliforniyakoplos,
          category: 'Rolu',
          menu: 'Копчений лосось, огірок, масаго',
        },
        {
          id: 58,
          name: 'Рол "Каліфорнія копчений лосось в кунжуті"',
          price: 240,
          gram: 250,
          image: rollkaliforniyakoploskun,
          category: 'Rolu',
          menu: 'Копчений лосось, огірок, кунжут',
        },

        // ЗАПЕЧЕНІ РОЛИ!!!!

        {
          id: 59,
          name: 'Запечений персик',
          price: 250,
          gram: 320,
          image: zapechrollpers,
          category: 'zaprolu',
          menu: 'Персик, крем-сир, авокадо, сирна шапочка',
        },
        {
          id: 60,
          name: 'Запечений вугор',
          price: 370,
          gram: 320,
          image: zapechrollvug,
          category: 'zaprolu',
          menu: 'Вугор, крем-сир, авокадо, сирна шапочка, унагі, кунжут',
        },
        {
          id: 61,
          name: 'Запечений лосось та креветка',
          price: 370,
          gram: 320,
          image: zapechrollloskrev,
          category: 'zaprolu',
          menu: 'Лосось, креветка, сирна шапочка, кисло-солодкий соус',
        },
        {
          id: 62,
          name: 'Запечений кунжут',
          price: 320,
          gram: 320,
          image: zapechrollkun,
          category: 'zaprolu',
          menu: 'Авокадо, лосось, крем-сир, кунжут, сирна шапочка',
        },
        {
          id: 63,
          name: 'Запечений спайсі',
          price: 320,
          gram: 320,
          image: zapechrollspaycy,
          category: 'zaprolu',
          menu: 'Авокадо,креветка ,крем-сир, сирна шапочка (соус шрірача), ікра масаго',
        },
        {
          id: 64,
          name: 'Запечений масаго',
          price: 320,
          gram: 320,
          image: zapechrollmas,
          category: 'zaprolu',
          menu: 'Лосось, крем-сир, авокадо, ікра масаго, сирна шапочка',
        },
        {
          id: 65,
          name: 'Запечений лосось',
          price: 320,
          gram: 320,
          image: zapechrolllos,
          category: 'zaprolu',
          menu: 'Лосось, авокадо, крем-сир, сирна шапочка, кисло-солодкий соус',
        },
        {
          id: 66,
          name: 'Запечений креветка',
          price: 320,
          gram: 320,
          image: zapechrollkrev,
          category: 'zaprolu',
          menu: 'Креветка, крем-сир, авокадо, кисло-солодкий соус',
        },

        // МАКІ!!!!

        {
          id: 67,
          name: 'Макі лосось',
          price: 140,
          gram: 150,
          image: makilos,
          category: 'maki',
        },
        {
          id: 68,
          name: 'Макі копчений лосось',
          price: 150,
          gram: 150,
          image: makikoplos,
          category: 'maki',
        },
        {
          id: 69,
          name: 'Макі вугор',
          price: 150,
          gram: 140,
          image: makivug,
          category: 'maki',
        },
        {
          id: 70,
          name: 'Макі креветка',
          price: 130,
          gram: 130,
          image: makikrev,
          category: 'maki',
        },
        {
          id: 71,
          name: 'Макі огірок',
          price: 80,
          gram: 140,
          image: makicucumber,
          category: 'maki',
        },
        {
          id: 72,
          name: 'Макі авокадо',
          price: 90,
          gram: 140,
          image: makiavocado,
          category: 'maki',
        },
        {
          id: 73,
          name: 'Макі лосось-сир',
          price: 140,
          gram: 140,
          image: makilossir,
          category: 'maki',
        },
        {
          id: 74,
          name: 'Макі сир',
          price: 120,
          gram: 140,
          image: makisir,
          category: 'maki',
        },

        // ШАУРМА!!!!

        {
          id: 121,
          name: 'Суші-шаурма Вугор',
          price: 300,
          valuest: '1 шт',
          // image: sushivug,
          category: 'sushishaurma',
        },
        {
          id: 122,
          name: 'Суші-шаурма Лосось',
          price: 280,
          valuest: '1 шт',
          // image: sushilos,
          category: 'sushishaurma',
        },
        {
          id: 123,
          name: 'Суші-шаурма Креветка',
          price: 280,
          valuest: '1 шт',
          // image: sushikrev,
          category: 'sushishaurma',
        },

        // НІГІРІ!!!!

        {
          id: 75,
          name: 'Нігірі',
          price: 60,
          gram: 60,
          image: nigiry,
          category: 'nigiry',
        },
        {
          id: 76,
          name: 'Нігірі вугор',
          price: 70,
          gram: 50,
          image: nigiryvug,
          category: 'nigiry',
        },

        // РАВЛИКИ!!!!
        // {
        //   id: 150,
        //   name: 'Равлики',
        //   prices: [
        //     { quantity: 1, price: 45 },
        //     { quantity: 3, price: 120 },
        //     { quantity: 6, price: 240 },
        //   ],
        //   valuest: '1 шт',
        //   image: snails,
        //   category: 'snails',
        //   menu: 'Равлики смачні',
        // },

        // СУПИ!!!!

        // {
        //   id: 77,
        //   name: 'Місо суп класичний',
        //   price: 90,
        //   gram: 300,
        //   category: 'soup',
        // },
        // {
        //   id: 78,
        //   name: 'Місо суп з лососем',
        //   price: 120,
        //   gram: 300,
        //   category: 'soup',
        // },
        // {
        //   id: 79,
        //   name: 'Місо суп з вугрем',
        //   price: 130,
        //   gram: 300,
        //   category: 'soup',
        // },
        // {
        //   id: 80,
        //   name: 'Місо суп з креветкою',
        //   price: 120,
        //   gram: 300,
        //   category: 'soup',
        // },
        { id: 81, name: 'Том ям', price: 170, gram: 300, category: 'soup' },

        // ГУНКАНИ!!!!

        {
          id: 82,
          name: 'Гункан лосось',
          price: 70,
          gram: 60,
          image: gunkanlos,
          category: 'gunkan',
        },
        {
          id: 83,
          name: 'Гункан вугор',
          price: 80,
          gram: 60,
          image: gunkanvug,
          category: 'gunkan',
        },
        {
          id: 84,
          name: 'Ікра масаго',
          price: 70,
          gram: 60,
          image: gunkanmas,
          category: 'gunkan',
        },
        {
          id: 85,
          name: 'Гункан червона ікра',
          price: 120,
          gram: 60,
          image: gunkanredikra,
          category: 'gunkan',
        },
        {
          id: 86,
          name: 'Гункан лосось сир',
          price: 90,
          gram: 70,
          image: gunkanlossir,
          category: 'gunkan',
        },
        {
          id: 87,
          name: 'Гункан вугор-сир',
          price: 90,
          gram: 70,
          image: gunkanvugsir,
          category: 'gunkan',
        },
        {
          id: 88,
          name: 'Гункан креветка-сир',
          price: 90,
          gram: 70,
          image: gunkankrevsir,
          category: 'gunkan',
        },
        {
          id: 89,
          name: 'Гункан креветка мікс',
          price: 80,
          gram: 60,
          image: gunkankrevmix,
          category: 'gunkan',
        },
        {
          id: 90,
          name: 'Гункан лосось-мікс',
          price: 80,
          gram: 60,
          image: gunkanlosmix,
          category: 'gunkan',
        },
        {
          id: 91,
          name: 'Гункан чука',
          price: 60,
          gram: 50,
          image: gunkanchyka,
          category: 'gunkan',
        },

        // НАПОЇ!!!!

        {
          id: 92,
          name: 'Кола',
          price: 30,
          litr: 0.5,
          image: cola,
          category: 'drink',
        },
        {
          id: 93,
          name: 'Фанта',
          price: 30,
          litr: 0.5,
          image: fanta,
          category: 'drink',
        },
        {
          id: 94,
          name: 'Спрайт',
          price: 30,
          litr: 0.5,
          image: sprite,
          category: 'drink',
        },
        {
          id: 95,
          name: 'Сік',
          price: 25,
          litr: 0.2,
          image: sik,
          category: 'drink',
        },

        // ДОДАТКИ!!!!
        {
          id: 96,
          name: 'Васабі',
          price: 25,
          image: vasabi,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 97,
          name: 'Імбир',
          price: 25,
          image: imbir,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 98,
          name: 'Соус соєвий',
          price: 25,
          image: soyeviy,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 99,
          name: 'Соус унагі',
          price: 25,
          image: ynagi,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 100,
          name: 'Соус горіховий',
          price: 25,
          image: gorix,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 101,
          name: 'Соус спайсі',
          price: 25,
          image: spaycy,
          valuest: '1 шт',
          category: 'dop',
        },
        {
          id: 102,
          name: 'Салат "Чука"',
          price: 130,
          gram: '120',
          category: 'salat',
        },
        {
          id: 103,
          name: 'Салат "Чука з вугрем"',
          price: 180,
          gram: '120',
          category: 'salat',
        },
        {
          id: 104,
          name: 'Салат "Чука з лососем"',
          price: 180,
          gram: '120',
          category: 'salat',
        },
        {
          id: 105,
          name: 'Спринг рол з лососем',
          price: 120,
          gram: '100',
          image: springlos,
          category: 'spring',
        },
        {
          id: 106,
          name: 'Спринг рол з креветкою',
          price: 120,
          gram: '100',
          image: springkrev,
          category: 'spring',
        },
        {
          id: 107,
          name: 'Поке з лососем',
          price: 250,
          gram: '300',
          image: pokelos,
          category: 'poke',
        },
        {
          id: 108,
          name: 'Поке з вугрем',
          price: 260,
          gram: '300',
          image: pokevug,
          category: 'poke',
        },
        {
          id: 109,
          name: 'Поке з креветкою',
          price: 240,
          gram: '300',
          image: pokekrev,
          height: '50px',
          category: 'poke',
        },
      ],
      showFullItem: false,
      fullItem: {},
      isAdmin: false,
      untensilsQuantity: 1,
      isModalOpen: true,
    };

    this.addToOrder = this.addToOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.chooseCategory = this.chooseCategory.bind(this);
    this.onShowItem = this.onShowItem.bind(this);
    this.createCheck = this.createCheck.bind(this);
    this.showAdminPage = this.showAdminPage.bind(this);
    this.updatePribory = this.updatePribory.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  handleFormSubmit(formData) {
    console.log('Form data submitted:', formData);
    this.handleModalClose();
  }

  render() {
    return (
      <div>
        <div className="wrapper">
          <Header
            orders={this.state.orders}
            onDelete={this.deleteOrder}
            createCheck={this.createCheck}
            updatePribory={this.updatePribory}
            utensilsQuantity={this.state.untensilsQuantity}
          />
          <Window />
          <Categories chooseCategory={this.chooseCategory} />
          <Items
            onShowItem={this.onShowItem}
            items={this.state.currentItems}
            onAdd={this.addToOrder}
            updateUtensilsQuantity={this.handleUtensilsIncrement}
          />
          {this.state.showFullItem && (
            <ShowFullItem
              onShowItem={this.onShowItem}
              onAdd={this.addToOrder}
              item={this.state.fullItem}
            />
          )}
          <Footer />
        </div>
        {/* {this.state.isModalOpen && (
          <ModalBucha
            isOpen={this.state.isModalOpen}
            onClose={this.handleModalClose}
            onSubmit={this.handleFormSubmit}
          />
        )} */}
      </div>
    );
  }

  onShowItem(item) {
    this.setState({ fullItem: item, showFullItem: !this.state.showFullItem });
  }

  chooseCategory(category) {
    if (category === 'all') {
      this.setState({ currentItems: this.state.items });
      return;
    }

    this.setState({
      currentItems: this.state.items.filter(el => el.category === category),
    });
  }

  deleteOrder(id) {
    this.setState({ orders: this.state.orders.filter(el => el.id !== id) });
  }

  addToOrder(item) {
    if (!this.state.orders.find(el => el.id === item.id)) {
      this.setState({ orders: [...this.state.orders, item] });
    }
  }
  createCheck(check) {
    this.setState(
      prevState => ({ checks: [...prevState.checks, check], orders: [] }),
      () => {}
    );
  }

  updatePribory = quantity => {
    this.setState({ untensilsQuantity: quantity });
  };

  showAdminPage(isAdmin) {
    this.setState({ isAdmin: isAdmin });
  }
}

export { App };
