import React, { Component } from 'react';
import background from '../images/background1.JPG';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [
        {
          key: 'all',
          name: 'Все',
        },
        {
          key: 'Set',
          name: 'Сети',
        },
        {
          key: 'Rolu',
          name: 'Роли',
        },
        {
          key: 'zaprolu',
          name: 'Запечені Роли',
        },
        {
          key: 'maki',
          name: 'Макі',
        },
        {
          key: 'nigiry',
          name: 'Нігірі',
        },
        // {
        //   key: 'snails',
        //   name: 'Равлики',
        // },
        {
          key: 'sushishaurma',
          name: 'Суші-шаурма',
        },
        {
          key: 'soup',
          name: 'Супи',
        },
        {
          key: 'salat',
          name: 'Салат',
        },
        {
          key: 'spring',
          name: 'Спринг-рол',
        },
        {
          key: 'poke',
          name: 'Поке',
        },
        {
          key: 'gunkan',
          name: 'Гункани',
        },
        {
          key: 'drink',
          name: 'Напої',
        },
        {
          key: 'dop',
          name: 'Додатки',
        },
      ],
    };
  }
  render() {
    return (
      <div>
        <div className="background">
          <div className="text-title">
            <p className="background-title">
              Наш основний напрямок - це найкращий,найякісніший продукт
              японської кухні.
            </p>
            <p className="background-title background-title-width">
              Ми з нетерпінням чекаємо на Ваше замовлення
            </p>
          </div>
          <img src={background} alt="бек" />
        </div>
        <div className="categories">
          {this.state.categories.map(el => (
            <div
              className="categories-btn"
              key={el.key}
              onClick={() => this.props.chooseCategory(el.key)}
            >
              {el.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Categories;
